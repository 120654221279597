import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Api, SSPApi} from '@sdk';
import {isEmpty, isEqual, toNumber} from 'lodash-es';
import type {FC} from 'react';
import type {ApplicationContext, Query, ReduxState, SSPArticleWithURL} from '../../lib/types';
import {searchAction} from '../../lib/store/actions';
import {getURLParams} from '../../lib/helpers/helpers';
import {useServerLanguage} from '../../lib/i18n';
import {AppContext} from '../../lib/context';
import {getCssRouteDefinition, getJSXRouteDefinition} from '../../lib/replaceWithSSPComponents';
import {CATEGORIES_DELIMITER_UI, ITEMS_PER_PAGE} from '../../lib/constants';
import {isBrowser} from '../../lib/helpers/browserHelpers';
import SEORoute from '../SEORoute';

Api.API_HOST = process.env.API_HOST as string;
const stateSelector = (store: ReduxState) => {
  const {loading} = store;
  const searchTerm = store.term;
  const searchPage = store.page;

  return {searchTerm, searchPage, loading};
};

const searchArticles = async ({
  term,
  page,
  categoryId,
  tag,
  dispatch,
  query,
  setLoading,
  setRows,
  setTotal,
}: {
  term?: string;
  page?: string | undefined;
  categoryId?: string;
  tag?: string;
  dispatch: (action: any) => void;
  setLoading: (state: boolean) => void;
  setRows: (rows: SSPArticleWithURL[]) => void;
  setTotal: (total: number) => void;
  query?: Query;
}) => {
  const accountId = query?.appInfo?.accountId;
  const lang = query?.appInfo?.settings.language;
  const libId = query?.appInfo?.libraryId;

  if (accountId && libId) {
    setLoading(true);
    try {
      const results = await SSPApi.search({
        accountId,
        libId,
        term,
        categoryId,
        tag,
        lang: lang || 'en',
        attachUseReferrerToRedirectURL: true,
        ...(page && {page: toNumber(page)}),
      });

      setRows(
        results.items.map(article => {
          if (article.contentUpdatedAt) {
            const updatedDate =
              article.contentUpdatedAt || article.createdAt
                ? new Date(article.contentUpdatedAt || article.createdAt)
                : undefined;

            return {...article, updatedDate};
          }

          return article;
        })
      );

      setTotal(results?.totalCount || 0);
      setLoading(false);
      dispatch(searchAction(term, page));
    } catch {
      setRows([]);
      setTotal(0);
      setLoading(false);
    }
  }
};

const Search: React.FC<{
  context: ApplicationContext;
}> = props => {
  const {searchTerm, searchPage, loading: loadingState} = useSelector(stateSelector);
  const dispatch = useDispatch();
  const {context} = props;

  const queryParams = isBrowser() ? getURLParams() : context.query.queryUrl || {};

  const {term, tag, category, categoryId, page} = queryParams ?? {};
  const isSearchResultBreadcrumbs = Boolean(term || tag || category);

  const {query} = context;

  const [clientSideRows, setClientSideRows] = useState<SSPArticleWithURL[]>([]);
  const [clientSidetotal, setClientSideTotal] = useState(0);
  const [loading, setLoading] = useState(loadingState || false);
  const isSearchFromServerSide = query?.searchResult !== undefined;

  const rows = isSearchFromServerSide ? query?.searchResult?.items : clientSideRows;

  const total = (isSearchFromServerSide ? query?.searchResult?.totalCount : clientSidetotal) || 0;

  useEffect(() => {
    if (isSearchFromServerSide) return;

    searchArticles({
      term,
      page,
      tag,
      categoryId,
      setLoading,
      setTotal: setClientSideTotal,
      setRows: setClientSideRows,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      query,
      dispatch,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const {term, page, tag, categoryId} = getURLParams();

    const termChanged = searchTerm && !isEqual(searchTerm, term);
    const pageChanged = searchPage && !isEqual(searchPage, page);

    if (termChanged || pageChanged) {
      setLoading(true);

      searchArticles({
        term: searchTerm,
        page: searchPage,
        tag,
        categoryId,
        setLoading,
        setTotal: setClientSideTotal,
        setRows: setClientSideRows,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        query,
        dispatch,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, searchPage]);

  const lang = query.appInfo?.settings?.language;
  const i18n = useServerLanguage(lang);

  const getResultText = (i18n: ReturnType<typeof useServerLanguage>) => {
    const searchResultForChipsPath = 'ssp-app.search.searchResultForChips';
    switch (true) {
      case term && term?.length > 0:
        return i18n.t('ssp-app.search.searchResultFor', {
          count: total,
          term,
          interpolation: {
            escapeValue: false,
          },
        });

      case tag && tag?.length > 0:
        return i18n.t(searchResultForChipsPath, {
          count: total,
          term: tag,
          chipName: i18n.t('ssp-app.search.tag'),
          interpolation: {
            escapeValue: false,
          },
        });
      case categoryId && categoryId?.length > 0:
        return i18n.t(searchResultForChipsPath, {
          count: total,
          term:
            rows && rows.length > 0
              ? rows[0].categories?.find(category => category.categoryId === categoryId)
                  ?.categoryName
              : category,
          chipName: i18n.t('ssp-app.search.category'),
          interpolation: {
            escapeValue: false,
          },
        });
      case Boolean(category?.length):
        return i18n.t(searchResultForChipsPath, {
          count: total,
          term: category,
          chipName: i18n.t('ssp-app.search.category'),
          interpolation: {
            escapeValue: false,
          },
        });
      default:
        return `${i18n.t(searchResultForChipsPath)}`;
    }
  };
  const getNoResultsText = (i18n: ReturnType<typeof useServerLanguage>) => {
    if (!term && !category && !tag) {
      return '';
    }

    return `${i18n
      .t('ssp-app.search.noResultsForArticles', {
        term: term || category || tag || '',
      })
      .replace('&gt;', CATEGORIES_DELIMITER_UI)}`; // one exception when we have hierarchical category
  };
  const isPaginationShown = total > ITEMS_PER_PAGE;

  const resultText = isEmpty(rows) ? getNoResultsText(i18n) : getResultText(i18n);

  const searchTitle = resultText;

  const htmlString = getJSXRouteDefinition(context) || '';
  const cssString = getCssRouteDefinition(context) || '';

  return (
    <SEORoute
      title={searchTitle}
      route={{
        bindings: {
          ...context,
          rows,
          isPaginationShown,
          total,
          loading,
          term,
          resultText,
          isSearchResultBreadcrumbs,
        },
        templateDefinition: query.templateDefinition,
        htmlString,
        cssString,
      }}
    />
  );
};

const SearchWrapper: FC = props => {
  const context = useContext(AppContext);

  if (!context) {
    return null;
  }

  return <Search {...props} context={context} />;
};

export default SearchWrapper;
